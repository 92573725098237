<template>
  <b-card no-body>
    <b-card-title>
      Contact
    </b-card-title>

    <!-- Name -->
    <b-row>
      <b-col sm="4">
        <form-validation-field
          v-model="localContact.name"
          vid="contact.name"
          name="contact-name"
          label="Contact Name"
          placeholder="Contact Name"
          aria-autocomplete="none"
          mode="eager"
          :rules="localContact.email ? 'required' : ''"
          :validation="{ customMessages: { 'required': 'The name field is required with a contact email.' }}"
        />
      </b-col>

      <!-- Email -->
      <b-col sm="4">
        <form-validation-field
          v-model="localContact.email"
          vid="contact.email"
          name="contact-email"
          label="Contact Email"
          type="email"
          placeholder="Contact Email"
          aria-autocomplete="none"
          mode="eager"
          :rules="localContact.name ? 'required|email' : 'email'"
          :validation="{ customMessages: { 'required': 'The email field is required with a contact name.' }}"
        />
      </b-col>

      <b-col sm="4">
        <b-form-group
          label="Contact Phone Number"
          label-for="contact-phone-number"
        >
          <b-form-input
            id="contact-phone-number"
            v-model="localContact.phone_number"
            placeholder="Contact Phone Number"
            aria-autocomplete="none"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    FormValidationField,
  },
  props: {
    contact: {
      type: Object,
      default: () => ({
        name: null,
        email: null,
        phone_number: null,
      }),
    },
  },
  data() {
    return {
      localContact: '',
    }
  },
  watch: {
    contact(value) {
      this.localContact = value
    },
    localContact: {
      handler(value) {
        // Loop through each property key of the localContact object.
        Object.keys(value).forEach(key => {
          // Check if the property value is an empty string.
          if (value[key] === '') {
            // If empty, set the property value to null.
            this.localContact[key] = null
          }
        })

        this.$emit('update:contact', value)
      },
      deep: true,
    },
  },
  mounted() {
    this.localContact = this.contact
  },
}
</script>

<style scoped>

</style>
