<template>
  <b-card>
    <validation-form
      ref="validationForm"
      :submit-buttons="{
        loading: form.isLoading,
        cancel: isNewOrganization,
      }"
      @validated="isNewOrganization ? create() : update()"
      @cancel="$router.push({ name: 'home' })"
    >
      <!-- Logo -->
      <image-input
        :file.sync="form.logo"
        :preview-src.sync="form.logo_url"
        label="Organization Logo"
      />

      <b-row class="my-2">
        <!-- Name -->
        <b-col sm="6">
          <form-validation-field
            v-model="form.name"
            name="organization-name"
            vid="name"
            rules="required"
            label="Organization Name*"
            placeholder="Organization Name"
            aria-autocomplete="none"
          />
        </b-col>

        <!-- Abbreviation -->
        <b-col sm="6">
          <form-validation-field
            v-model="form.abbreviation"
            rules="max:8"
            vid="abbreviation"
            name="organization-abbreviation"
            label="Organization Abbreviation"
            placeholder="Organization Abbreviation"
            aria-autocomplete="none"
          />
        </b-col>
      </b-row>

      <!-- Social Media -->
      <form-social-media
        :social-media="form.social_media || undefined"
        @update:social-media="form.social_media = $event"
      />

      <!-- Contact -->
      <form-contact
        :contact="form.contact || undefined"
        @update:contact="form.contact = $event"
      />

      <!-- System settings. Only show these to TurboStats system administrators. -->
      <b-card
        v-if="userUsedMasterPassword()"
        no-body
      >
        <b-card-title>System Settings</b-card-title>

        <b-row>
          <b-col sm="4">
            <form-validation-field
              v-model="form.slug"
              name="slug"
              vid="name"
              rules="required"
              label="Slug*"
              placeholder="The organization folder name"
              aria-autocomplete="none"
              :disabled="!isNewOrganization"
            />
          </b-col>
        </b-row>
      </b-card>

    </validation-form>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BRow, BCol,
} from 'bootstrap-vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import FormSocialMedia from '@/components/forms/FormSocialMedia.vue'
import FormContact from '@/components/forms/FormContact.vue'
import ImageInput from '@/components/forms/images/ImageInput.vue'

import Form from '@/forms/Form'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import axios from '@/libs/axios'
import useFlash from '@/composables/useFlash'
import { userUsedMasterPassword } from '@/auth/utils'

const { flashError } = useFlash()

export default {
  name: 'OrganizationInformation',
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    ValidationForm,
    FormValidationField,
    ImageInput,
    FormSocialMedia,
    FormContact,
  },
  data() {
    return {
      form: new Form({
        name: '',
        abbreviation: '',
        contact: null,
        logo_url: '',
        social_media: null,
        logo: undefined,
        slug: null,
      }, true),
    }
  },
  computed: {
    ...mapGetters('account', ['organization']),
    isNewOrganization() {
      return this.$route.name === 'organization.create'
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.name === 'organization.create') {
      next(vm => {
        vm.form.reset()
      })
      return
    }

    axios.get(`organizations/${store.state.account.activeOrganization}`)
      .then(response => {
        const { data } = response.data

        next(vm => {
          Object.assign(vm.form, data)
        })
      })
      .catch(error => {
        flashError(error)
      })
  },
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    ...mapMutations('app', ['UPDATE_PAGE_TITLE', 'UPDATE_PAGE_IMAGE']),
    ...mapMutations('account', ['UPDATE_ORGANIZATION', 'UPDATE_ACTIVE_ORGANIZATION', 'ADD_ORGANIZATION']),
    userUsedMasterPassword,
    create() {
      this.form.post('/organizations')
        .then(response => {
          const { data } = response

          this.ADD_ORGANIZATION(data)
          this.UPDATE_ACTIVE_ORGANIZATION(data.id)
          this.$router.push({ name: 'organization.information' })
        })
    },
    update() {
      this.form.post(`/organizations/${this.$store.state.account.activeOrganization}`, false, true)
        .then(response => {
          const { data } = response

          this.UPDATE_ORGANIZATION(data)
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
